.App {
  display: grid;
  grid-template-areas:
    'nav header header'
    'nav content content';
  grid-template-columns: 65px 1fr;
  grid-template-rows: 50px 1fr;
  grid-gap: 0em;
  height: 100vh;
  background-color: whitesmoke;
  min-width: 50em;
  min-height: 50em;
}

header {
  grid-area: header;
  position: relative;
}

nav {
  grid-area: nav;
  background-color: #32363f;
}

main {
  grid-area: content;
  padding: 2em;
  background-color: whitesmoke;
}

.active path {
  color: white;
}
